<template>
  <div class="text-left">
    <v-container fluid class="text-center">
      <v-img
        src="@/assets/logo.png"
        alt="MediSure"
        width="100"
        class="center-image mb-2"
      />

      <strong>MEDISURE PLUS DIAGNOSTICS LABORATORY</strong><br />
      <strong>MEDISURE ONLINE INC.</strong><br />

      16B WILLIAMS ST. HIGHWAY HILLS, MADALUYONG CITY<br />
      <span class="mr-5">TEL. NO.: 864-28781</span>
      <span>EMAIL ADDRESS: mso.lab@medisureonline.com</span><br /><br />

      <strong>
        {{ header }}
        <br v-show="subheader" />
        <span v-show="subheader">{{ subheader }}</span>
      </strong>
    </v-container>

    <strong>Patient's Name:</strong>
    &nbsp;{{ lastName }}, {{ firstName }} {{ middleName }}<br />

    <strong>Birthday:</strong> {{ dateOfBirth }}<br />

    <strong>Age/Sex:</strong> {{ String(age) }} / {{ gender }}<br />

    <strong>Report Date:</strong> {{ apeDateDisplay }}
  </div>
</template>

<script>
import {
  computeAge,
  getVerboseDateStringFromTimestamp
} from "@/common/utilities/date";
import { mapGetters } from "vuex";

export default {
  props: {
    header: String,
    subheader: String
  },

  data: () => ({
    age: "",
    dateOfBirth: "",
    firstName: "",
    gender: "",
    lastName: "",
    middleName: ""
  }),

  computed: {
    ...mapGetters({
      apeDate: "getActiveApeDate",
      userCode: "getActiveUserCode",
      userInfo: "getActiveUserInfo"
    }),

    apeDateDisplay() {
      return getVerboseDateStringFromTimestamp(this.apeDate);
    }
  },

  mounted() {
    this.age = computeAge(
      new Date(this.apeDate),
      new Date(this.userInfo.birthday)
    );
    this.dateOfBirth = this.userInfo.birthday || "";
    this.firstName = this.userInfo.firstName || "";
    this.gender = this.userInfo.gender || "";
    this.lastName = this.userInfo.lastName || "";
    this.middleName = this.userInfo.middleName || "";
  }
};
</script>

<style scoped>
/* A4: 715px width */

.center-image {
  margin: auto;
}
</style>
