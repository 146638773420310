<template>
  <div>
    <Header class="mb-5" header="CLINICAL MICROSCOPY" subheader="Fecalysis" />

    <p class="mt-8 text-center"><strong>MACROSCOPIC</strong></p>

    <table class="text-left">
      <tr>
        <td width="125">COLOR</td>
        <td width="100">{{ fecalysisData.color }}</td>
      </tr>

      <tr>
        <td>CONSISTENCY</td>
        <td>{{ fecalysisData.consistency }}</td>
      </tr>
    </table>

    <p class="mt-8 text-center"><strong>MICROSCOPIC</strong></p>

    <table class="text-left">
      <tr>
        <td width="125">WBC</td>
        <td width="100">{{ fecalysisData.wbc }}</td>
        <td width="200"><strong>0-4/HPO</strong></td>
        <td width="125">Fat Globules</td>
        <td width="100">{{ fecalysisData.fatGlobules }}</td>
      </tr>

      <tr>
        <td>RBC</td>
        <td>{{ fecalysisData.rbc }}</td>
        <td style="font-weight: bold;">0-3/HPO</td>
        <td>Yeast Cells</td>
        <td>{{ fecalysisData.yeastCells }}</td>
      </tr>

      <tr>
        <td>BACTERIA</td>
        <td>{{ fecalysisData.bacteria }}</td>
      </tr>
    </table>

    <table class="text-left">
      <tr>
        <td width="125">PARASITE</td>
        <td width="330" v-show="parasiteCount === 1">
          {{ parasiteCount > 0 ? fecalysisData.parasites[0].parasite : "" }}
          &nbsp;
          <span v-show="parasiteCount > 0 && fecalysisData.parasites[0].hpo">
            {{ parasiteCount > 0 ? fecalysisData.parasites[0].hpo : "" }}
          </span>
        </td>
      </tr>

      <tr
        v-show="parasiteCount > 1"
        v-for="(parasite, i) in fecalysisData.parasites"
        :key="i"
      >
        <td width="125" />
        <td width="330">
          {{ parasite.parasite }}&nbsp;
          <span v-show="parasite.hpo">{{ parasite.hpo }}</span>
        </td>
      </tr>
    </table>

    <div class="mt-5 show-borders" style="height: 160px;">
      <div class="ma-3 smaller-bold">REMARKS</div>

      <div class="ma-3 text-left">{{ fecalysisData.remarks }}</div>
    </div>
    <br class="lineHeight"> 
    <div class="mt-15">
      <v-img src="@/assets/Results/signatures.png" alt="signatures" />
    </div>
  </div>
</template>

<script>
import Header from "./Header.vue";

export default {
  components: {
    Header
  },

  props: {
    fecalysisData: Object
  },

  computed: {
    parasiteCount() {
      return this.fecalysisData.parasites
        ? this.fecalysisData.parasites.length || 0
        : 0;
    }
  }
};
</script>

<style scoped>
.center {
  margin: auto;
  width: 50%;
  padding: 10px;
}

.show-borders {
  border: 1px solid black;
  border-collapse: collapse;
}

.smaller-bold {
  font-size: small;
  font-weight: bold;
}
.lineHeight{
  line-height:100px;
}
</style>
