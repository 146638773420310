<template>
  <v-subheader class="justify-center mt-n5">
    {{ countString }}
  </v-subheader>
</template>

<script>
import {
  STAGE,
  getActiveOffsiteService,
  getSubmissions
} from "@/store/offsiteService";

export default {
  name: "Attendees",

  data: () => ({
    countString: ""
  }),

  mounted() {
    this.refresh();
  },

  methods: {
    refresh() {
      this.countString = "Loading attendee count...";
      this.activeService = getActiveOffsiteService();

      getSubmissions(this.activeService.id).then(data => {
        let checkinCount = 0;
        let checkoutCount = 0;
        let registrants = 0;

        for (const key in data) {
          if (data[key][STAGE.CHECKIN]) {
            checkinCount++;
          }

          if (data[key][STAGE.CHECKOUT]) {
            checkoutCount++;
          }

          registrants++;
        }

        this.countString = `${registrants} registrants:  ${checkinCount} checked in, ${checkoutCount} checked out`;
      });
    }
  }
};
</script>
