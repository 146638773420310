<template>
  <v-container fluid>
    <v-row>
      <v-col cols="6" class="no-padding-on-print">
        <v-img src="@/assets/logo.png" alt="MediSure" width="100" />
      </v-col>
      <v-col cols="6" class="no-padding-on-print text-right">
        <strong>APE / PRE-EMPLOYMENT<br />{{ description }}</strong>
        <br /><br />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="8" />
      <v-col cols="4">
        <TextField label="Date" :value="getDate(apeDate)" class="text-right" />
      </v-col>
    </v-row>

    <v-row class="mt-3">
      <v-col cols="4">
        <TextField label="Patient ID" :value="userCode" />
      </v-col>
    </v-row>

    <Header3 :userCode="userCode" :userInfo="userInfo" />
  </v-container>
</template>

<script>
import { getDate } from "@/common/utilities/date";

import Header3 from "./Header3.vue";
import TextField from "./TextField.vue";

export default {
  name: "Header1",

  components: {
    Header3,
    TextField
  },

  props: {
    apeDate: Date,
    description: String,
    userCode: String,
    userInfo: Object
  },

  data: () => ({
    getDate: getDate
  })
};
</script>
