<template>
  <v-container>
    <v-row v-if="isUserCodeValid && !name">
      <v-col cols="12" class="py-0 text-center red--text">
        User code is invalid
      </v-col>
    </v-row>

    <v-row v-else>
      <v-col cols="12" class="py-0 text-center" style="font-weight: bold;">
        {{ name }}{{ gender }}
      </v-col>

      <v-col cols="12" class="py-0 text-center">
        {{ birthday }}{{ age }}
      </v-col>

      <v-col v-if="serviceDetails" cols="12" />

      <v-col v-if="serviceDetails" cols="12" class="py-0 text-center">
        {{ serviceDetails }}
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getUserDetails } from "@/Registration/store";

export default {
  name: "UserInfo",

  props: {
    code: String,
    isUserCodeValid: Boolean
  },

  data: () => ({
    name: "",
    image: "",
    age: "",
    birthday: "",
    gender: "",
    serviceDetails: ""
  }),

  methods: {
    getGender(gender) {
      if (gender) {
        if ("M" === gender) {
          return " (MALE)";
        } else if ("F" === gender) {
          return " (FEMALE)";
        }
      }

      return "";
    }
  },

  watch: {
    code(value) {
      getUserDetails(value).then(details => {
        this.name = details.firstname
          ? `${details.firstname} ${details.lastName}`
          : "";
        this.age = details.age ? ` (${details.age})` : "";
        this.birthday = details.birthday || "";
        this.gender = this.getGender(details.gender);
        this.serviceDetails =
          details.service && details.service.name && details.service.schedule
            ? `${details.service.name} (${details.service.schedule})`
            : "";

        this.$emit("onUserInfo", details);
      });
    }
  }
};
</script>
