<template>
  <v-container fluid class="main-container text-center">
    <PageHeader header="Upload Photo" class=" mb-10" />

    <UserCode
      @onInvalidUserCode="onInvalidUserCode"
      @onValidUserCode="onValidUserCode"
    />

    <UserInfoWithValidation
      :code="userCode"
      @onUserInfo="onUserInfo"
      :isUserCodeValid="isUserCodeValid"
    />

    <v-row>
      <v-col class="text-center">
        <img
          v-show="isUserCodeValid && serviceId"
          :src="imageSrc"
          alt="User Image"
          style="border: solid; min-height: 50px; min-width: 300px; width: 600px"
        />
      </v-col>
    </v-row>

    <input
      ref="input"
      v-show="isUserCodeValid && serviceId"
      type="file"
      accept="image/*"
      @change="setImage"
      style="max-width: 320px"
    />

    <v-row class="mt-10">
      <v-btn
        :disabled="!imageSrc"
        v-show="isUserCodeValid && serviceId"
        @click="addPhoto"
        color="primary"
        style="margin: auto; max-width: 320px;"
      >
        {{ savePhotoText }}
      </v-btn>
    </v-row>

    <ErrorMessage :errorMessage="errorMessage" />

    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
import { addPhoto, getPhoto } from "../store";

import ConfirmDlg from "@/components/ConfirmDlg.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import PageHeader from "@/components/PageHeader.vue";
import UserCode from "@/components/UserCode.vue";
import UserInfoWithValidation from "@/components/UserInfoWithValidation.vue";

export default {
  name: "UploadPhoto",

  components: {
    ConfirmDlg,
    ErrorMessage,
    PageHeader,
    UserCode,
    UserInfoWithValidation
  },

  data: () => ({
    isUserCodeValid: false,
    userCode: "",
    imageSrc: "",

    serviceId: "",

    savePhotoText: "Add Patient Photo",
    errorMessage: ""
  }),

  methods: {
    addPhoto() {
      addPhoto(this.serviceId, this.userCode, this.imageSrc)
        .then(() => {
          this.$refs.confirm.open("Confirmation", "Photo upload completed", {
            noconfirm: true
          });
          this.savePhotoText = "Update Patient Photo";
        })
        .catch(err => {
          this.imageSrc = "";
          this.errorMessage = "Checkin Error: " + err;
        });
    },

    onUserInfo(userInfo) {
      this.serviceId =
        userInfo && userInfo.service && userInfo.service.id
          ? userInfo.service.id
          : "";
    },

    onInvalidUserCode(userCode) {
      this.userCode = userCode;
      this.imageSrc = "";

      this.isUserCodeValid = false;
      this.savePhotoText = "Add Patient Photo";
    },

    onValidUserCode(userCode) {
      this.userCode = userCode;
      this.isUserCodeValid = true;

      getPhoto(this.serviceId, this.userCode)
        .then(result => {
          this.imageSrc = result.photo;
          this.savePhotoText = result.photo
            ? "Update Patient Photo"
            : "Add Patient Photo";
        })
        .catch(err => (this.errorMessage = "getPhoto Error: " + err));
    },

    setImage(e) {
      const file = e.target.files[0];
      if (file.type.indexOf("image/") === -1) {
        return;
      }

      if (file.size > 950 * 1024) {
        this.errorMessage =
          "Image size should be less than 950 kB. Please pick another file.";
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();
        reader.onload = event => {
          this.imageSrc = event.target.result;
        };
        reader.readAsDataURL(file);
      }
    }
  }
};
</script>
