export function displayAmount(amount) {
  let candidate = Math.abs(amount).toFixed(2);
  let sign = amount < 0 ? "-" : "";

  let display = candidate.substring(candidate.indexOf("."));
  candidate = candidate.substring(0, candidate.indexOf("."));
  while (candidate.length > 3) {
    display = `,${candidate.substring(candidate.length - 3)}${display}`;
    candidate = candidate.substring(0, candidate.length - 3);
  }

  return sign + candidate + display;
}

export function displayPesoAmount(amount) {
  return `₱ ${displayAmount(amount)}`;
}

export function displayWholeNumber(number) {
  let candidate = Math.abs(number).toFixed(0);
  let display = "";
  let sign = number < 0 ? "-" : "";

  while (candidate.length > 3) {
    display = `,${candidate.substring(candidate.length - 3)}${display}`;
    candidate = candidate.substring(0, candidate.length - 3);
  }

  return sign + candidate + display;
}

export function padLeftZero(number) {
  return (number < 10 ? "0" : "") + number.toString();
}
