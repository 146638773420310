<template>
  <div>
    <div class="footer mt-0 text-center">
      <PhysicalExam />
    </div>

    <div class="footer mt-10 text-center" />

    <div
      class="footer mt-10 text-center"
      v-show="Object.keys(fecalysisData).length > 0"
    >
      <Fecalysis :fecalysisData="fecalysisData" />
    </div>

    <div class="footer mt-10 text-center">
      <Urinalysis :urinalysisData="urinalysisData" />
    </div>

    <div class="footer mt-10 text-center" v-show="hasCBCData">
      <ShowResults
        :resultCode="DATA.CBC"
        :serviceId="serviceId"
        :userCode="userCode"
        @onData="hasCBCData = $event"
      />
      <strong>CBC</strong> <br />
    </div>

    <div class="footer mt-10 text-center" v-show="hasECGData">
      <ShowResults
        :resultCode="DATA.ECG"
        :serviceId="serviceId"
        :userCode="userCode"
        @onData="hasECGData = $event"
      />
      <strong>ECG</strong> <br />
    </div>

    <div class="footer mt-10 text-center" v-show="hasPapSmearData">
      <ShowResults
        :resultCode="DATA.PAP_SMEAR"
        :serviceId="serviceId"
        :userCode="userCode"
        @onData="hasPapSmearData = $event"
      />
      <strong>PAP SMEAR</strong> <br />
    </div>

    <div class="footer mt-10 text-center" v-show="hasXrayData">
      <ShowResults
        :resultCode="DATA.XRAY"
        :serviceId="serviceId"
        :userCode="userCode"
        @onData="hasXrayData = $event"
      />
      <strong>X-RAY</strong> <br />
    </div>
  </div>
</template>

<script>
import {
  getUrinalysisData,
  getUrinalysisDataFromResult,
  getFecalysisData,
  getFecalysisDataFromResult
} from "@/SampleSubmissions/store";
import { DATA, getActiveOffsiteService } from "@/store/offsiteService";

import Fecalysis from "../components/Results/Fecalysis.vue";
import PhysicalExam from "../components/Results/PhysicalExam.vue";
import ShowResults from "../components/ShowResults.vue";
import Urinalysis from "../components/Results/Urinalysis.vue";

export default {
  components: {
    Fecalysis,
    PhysicalExam,
    ShowResults,
    Urinalysis
  },

  data: () => ({
    serviceId: "",
    userCode: "",
    fecalysisData: {},
    urinalysisData: {},

    hasCBCData: false,
    hasECGData: false,
    hasPapSmearData: false,
    hasXrayData: false,

    DATA: DATA
  }),

  mounted() {
    this.loadData();
  },

  watch: {
    resultCode() {
      this.loadData();
    },

    serviceId() {
      this.loadData();
    },

    userCode() {
      this.loadData();
    }
  },

  methods: {
    getFecalysis() {
      return new Promise((resolve, reject) => {
        this.fecalysisData = {};

        getFecalysisData(this.serviceId, this.userCode)
          .then(result => {
            if (Object.keys(result).length > 0) {
              const data = getFecalysisDataFromResult(result);
              this.fecalysisData = { ...data };
            }
            resolve();
          })
          .catch(err => {
            this.errorMessage = `Get submissions error: ${err}`;
            reject(err);
          });
      });
    },

    getUrinalysis() {
      return new Promise((resolve, reject) => {
        this.urinalysisData = {};

        getUrinalysisData(this.serviceId, this.userCode)
          .then(result => {
            const data = getUrinalysisDataFromResult(result);
            this.urinalysisData = { ...data };
            resolve();
          })
          .catch(err => {
            this.errorMessage = `Get submissions error: ${err}`;
            reject(err);
          });
      });
    },

    loadData() {
      const activeService = getActiveOffsiteService();
      this.serviceId = activeService.id;

      this.userCode = this.$store.getters["getActiveUserCode"];

      this.getFecalysis().then(() => {
        return this.getUrinalysis();
      });
    }
  }
};
</script>

<style lang="scss" scoped>
.date {
  margin-left: 500px;
  width: 220px;
}

.equal-columns {
  table-layout: fixed;
  width: 100%;
}

.show-borders {
  border: 1px solid black;
  border-collapse: collapse;
}

.smaller-bold {
  font-size: small;
  font-weight: bold;
}

.header-entry {
  font-weight: bold;
  font-size: smaller;

  @extend .show-borders;
}

.height-30 {
  height: 30px;
}

@media print {
  @page {
    size: auto;
    margin: 10mm 10mm 10mm 10mm;
  }

  .footer {
    page-break-after: always;
  }
}

.width-26 {
  width: 190px;
}

.width-left {
  width: 450px;
}

.width-right {
  margin-left: 25px;
  width: 240px;
}
</style>
