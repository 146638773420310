<template>
  <v-container fluid class="main-container">
    <PageHeader
      header="Registration"
      :subheader="activeService.name || 'No service selected'"
    />
    <Attendees />

    <MenuItems class="mt-5" />
  </v-container>
</template>

<script>
import { getActiveOffsiteService } from "@/store/offsiteService";

import Attendees from "../components/Attendees.vue";
import MenuItems from "../components/MenuItems.vue";
import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "Menu",

  components: {
    Attendees,
    MenuItems,
    PageHeader
  },

  data: () => ({
    activeService: { id: null, name: null }
  }),

  mounted() {
    this.activeService = getActiveOffsiteService();
  }
};
</script>
