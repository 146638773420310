import Vue from "vue";
import VueRouter from "vue-router";

import store from "@/store";

import { getRoutes } from "@/Registration/routes";

import LabRequestForm from "@/views/LabRequestForm.vue";
import Loading from "@/views/Loading.vue";
import NotAllowed from "@/views/NotAllowed.vue";
import SelectCamera from "@/views/SelectCamera.vue";
import SetActiveService from "@/views/SetActiveService.vue";
import Signin from "@/views/Signin.vue";

export const ROUTE = {
  ADD_DATA: "/data",
  MENU: "/menu",
  SET_ACTIVE_SERVICE: "/setActiveService"
};

Vue.use(VueRouter);

const guard = async (to, from, next) => {
  if (store.state.auth.loggedIn) {
    if (store.getters.isAllowed) {
      return next();
    } else {
      return next(ROUTE.MENU);
    }
  } else {
    return next("/signin");
  }
};

const routes = [
  {
    path: "/signin",
    name: "Signin",
    component: Signin
  },
  {
    path: "/loading",
    name: "Loading",
    component: Loading
  },
  {
    path: "/notallowed",
    name: "NotAllowed",
    component: NotAllowed
  },
  {
    path: "/labRequestForm",
    name: "LabRequestForm",
    component: LabRequestForm
  },

  // guarded routes
  {
    path: "/selectCamera",
    name: "SelectCamera",
    component: SelectCamera,
    beforeEnter: guard
  },
  {
    path: ROUTE.SET_ACTIVE_SERVICE,
    name: "SetActiveService",
    component: SetActiveService,
    beforeEnter: guard
  },
  ...getRoutes(ROUTE, guard)
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export function routeTo(path) {
  if (router.currentRoute.fullPath !== path) {
    router.push(path);
  }
}

export default router;
