<template>
  <div>
    <div class="d-flex">
      <TextField label="Last Name" :value="lastName" class="mr-2 width-30" />
      <TextField label="First Name" :value="firstName" class="mr-2 width-45" />
      <TextField label="Middle Name" :value="middleName" class="width-25" />
    </div>

    <div class="d-flex">
      <TextField label="Date of Birth" :value="dateOfBirth" class="mr-2" />
      <TextField label="Age" :value="String(age)" class="mr-2" />
      <TextField label="Gender" :value="gender" />
    </div>

    <div class="d-flex">
      <TextField
        label="Mobile No"
        :value="mobileNumber"
        class="mr-2 width-30"
      />
      <TextField label="Email Address" :value="emailAddress" class="width-45" />
    </div>

    <div class="d-flex">
      <TextField
        label="Residence Address"
        :value="residenceLine1"
        class="width-100"
      />
    </div>

    <div class="d-flex">
      <TextField label="" :value="residenceLine2" class="width-100" />
    </div>

    <div class="d-flex">
      <TextField label="Company Name" :value="companyName" class="width-100" />
    </div>
  </div>
</template>

<script>
import { computeAge } from "@/common/utilities/date";
import { getUserDetails } from "../utilities/user";
import { mapGetters } from "vuex";

import TextField from "./TextField.vue";

export default {
  name: "Header3",

  components: {
    TextField
  },

  data: () => ({
    age: "",
    companyName: "",
    dateOfBirth: "",
    emailAddress: "",
    firstName: "",
    gender: "",
    lastName: "",
    middleName: "",
    mobileNumber: "",
    residenceLine1: "",
    residenceLine2: "."
  }),

  computed: {
    ...mapGetters({
      apeDate: "getActiveApeDate",
      userCode: "getActiveUserCode",
      userInfo: "getActiveUserInfo"
    })
  },

  mounted() {
    this.age = computeAge(
      new Date(this.apeDate),
      new Date(this.userInfo.birthday)
    );
    this.dateOfBirth = this.userInfo.birthday || "";
    this.firstName = this.userInfo.firstName || "";
    this.gender = this.userInfo.gender || "";
    this.lastName = this.userInfo.lastName || "";
    this.middleName = this.userInfo.middleName || "";

    getUserDetails(this.userCode).then(info => {
      this.emailAddress = info.email;
      this.mobileNumber = info.contactNumber;

      const address = info.address;
      if (address) {
        this.residenceLine1 = address.addressLine1;
        this.residenceLine2 = [
          address.barangay,
          address.municipality,
          address.province,
          address.region
        ].join(", ");
      }
    });
  }
};
</script>

<style scoped>
/* A4: 715px width */

.width-11 {
  width: 80px;
}

.width-15 {
  width: 110px;
}

.width-25 {
  width: 180px;
}

.width-30 {
  width: 215px;
}

.width-45 {
  width: 320px;
}

.width-100 {
  width: 715px;
}
</style>
