<template>
  <v-container fluid class="menu-container" style="text-align: center">
    <PageHeader header="Add Offsite Walk in Service" />

    <v-form lazy-validation ref="form" @submit.prevent="saveService()">
      <v-date-picker
        v-model="date"
        :min="now"
        :max="oneYearLater"
        class="mt-5"
      />

      <v-row class="format-text-field mt-5">
        <TimePicker
          label="From"
          :isDisabled="!isDateSelected"
          selectedTimeEntry="08:00 AM"
          @onChange="fromChanged"
        />
      </v-row>

      <v-row class="format-text-field">
        <ToTimePicker
          label="To"
          :isDisabled="!isDateSelected"
          :startTime="startTime"
          :key="startTime"
          @onChange="toChanged"
        />
      </v-row>

      <!-- <v-row class="format-text-field">
        <v-text-field
          label="Patients to accommodate every 30 minutes"
          :disabled="!isDateSelected"
          persistent-hint
          :hint="appointmentSlotsHint"
          v-model="appointmentSlots"
          @change="appointmentSlotsChanged"
        />
      </v-row> -->

      <v-row class="spacer-top">
        <v-col cols="8"> </v-col>

        <v-col cols="4" class="right-align">
          <v-btn
            :disabled="isSaveDisabled"
            color="green"
            class="white--text"
            type="submit"
          >
            Save
          </v-btn>
        </v-col>
      </v-row>
    </v-form>

    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
import { TRANSACTION_TYPE_WALKIN as WALKIN } from "@/common/utilities/appointments";

import { addService } from "@/store/offsiteService";
import { getDateString } from "@/common/utilities/date";
import { GetTime } from "@/common/utilities/time";

import ConfirmDlg from "@/common/components/ConfirmDialog.vue";
import PageHeader from "@/components/PageHeader.vue";
import TimePicker from "@/components/TimePicker.vue";
import ToTimePicker from "@/components/ToTimePicker.vue";

export default {
  components: {
    ConfirmDlg,
    PageHeader,
    TimePicker,
    ToTimePicker
  },

  data: () => ({
    date: "",

    now: "",
    oneYearLater: "",
    version: "2.0",
    serviceId: "",
    serviceSchedules: [],

    appointmentSlots: "10",
    appointmentSlotsHint: "",

    selectedFromValue: GetTime(8, 0, "AM"),
    selectedToValue: GetTime(7, 0, "PM"),

    startTime: 1850
  }),

  mounted() {
    this.resetFields();
  },

  computed: {
    isDateSelected() {
      return this.date;
    },

    isSaveDisabled() {
      return 0 === this.appointmentSlots.length || !this.date;
    }
  },

  methods: {
    addService() {
      addService({
        name: WALKIN,
        schedule: {
          start: new Date(`${this.date} ${this.selectedFromValue.text}`),
          end: new Date(`${this.date} ${this.selectedToValue.text}`)
        },
        version: this.version
      }).then(() => {
        if (
          this.$refs.confirm.open(
            "Service Saved",
            "This service has been saved.",
            { noconfirm: true }
          )
        ) {
          this.resetFields();
        }
      });
    },

    fromChanged(time) {
      this.selectedFromValue = { ...time };
      this.startTime = time.value;
    },

    resetFields() {
      this.date = "";

      let _date = new Date();
      this.now = getDateString(_date);
      _date.setFullYear(_date.getFullYear() + 1);
      this.oneYearLater = getDateString(_date);

      this.appointmentSlots = "10";
      this.appointmentSlotsHint = "";

      this.selectedFromValue = GetTime(7, 30, "AM");
      this.selectedToValue = GetTime(8, 0, "PM");
    },

    async saveService() {
      if (
        await this.$refs.confirm.open(
          "Confirm",
          "Are you sure you want to save this service?"
        )
      ) {
        this.addService();
      }
    },

    toChanged(time) {
      this.selectedToValue = { ...time };
    },

    //-------------------------------- OLD --------------------------------//

    appointmentSlotsChanged() {
      let slots = parseInt(this.appointmentSlots);
      if (this.appointmentSlots.trim().length == 0) {
        this.appointmentSlotsHint =
          "Please indicate number of patients to accommodate";
      } else if (isNaN(slots)) {
        this.appointmentSlotsHint = "This should be a number";
      } else {
        this.appointmentSlotsHint = "";
      }
    }
  }
};
</script>

<style scoped>
.format-text-field {
  margin: auto;
  max-width: 320px;
}

.right-align {
  text-align: right;
}

.spacer-top {
  margin-top: 50px;
}
</style>
