<template>
  <div style="font-size: small;">
    <div class="text-center" width="600">
      <div class="mr-3 d-inline-block">
        <img src="@/assets/philhealth.png" alt="PhilHealth" height="30" />
      </div>

      <div class="mt-2 align-top d-inline-block">
        <strong>PhilHealth Konsulta Registration Form</strong>
      </div>
    </div>

    <div class="ml-12 mt-5">
      <table class="text-left">
        <tr>
          <td>
            <strong>Name:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 535px;">
            {{ fullName }}
          </td>
        </tr>

        <tr>
          <td>
            <strong>PIN:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 535px;">{{ pin }}</td>
        </tr>
      </table>

      <table class="text-left">
        <tr>
          <td>
            <strong>Member:</strong>
          </td>
          <td
            class="text-center"
            style="border-bottom: 1px solid; width: 50px;"
          >
            <span v-show="isMember">&#x2713;</span>
          </td>
          <td>
            <strong>Dependent:</strong>
          </td>
          <td
            class="text-center"
            style="border-bottom: 1px solid; width: 50px;"
          >
            <span v-show="!isMember">&#x2713;</span>
          </td>
        </tr>
      </table>

      <table class="text-left">
        <tr>
          <td>
            <strong>Email Address</strong> (if applicable)
            <strong>:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 395px;">
            {{ emailAddress }}
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-12 mt-5">
      <strong>Preferred PhilHealth Konsulta Facility and Address</strong><br />
      (Municipality/Town/City/Province):
      <br />

      <table class="text-left">
        <tr>
          <td>
            <strong>1<sup>st</sup> choice:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 500px;">
            MEDISURE ONLINE, INC.,
            <span style="font-size: smaller;">
              16B WILLIAMS ST. HIGHWAY HILLS, MADALUYONG CITY
            </span>
          </td>
        </tr>

        <tr>
          <td>
            <strong>2<sup>nd</sup> choice:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 500px;">N/A</td>
        </tr>

        <tr>
          <td>
            <strong>3<sup>rd</sup> choice:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 500px;">N/A</td>
        </tr>
      </table>
    </div>

    <div class="ml-12 mt-12">
      <table class="text-center">
        <tr>
          <td style="width: 270px;" />
          <td style="border-bottom: 1px solid; width: 300px;">
            {{ fullName }}
          </td>
        </tr>

        <tr>
          <td />
          <td style="font-size: x-small;">
            Signature over printed name
          </td>
        </tr>
      </table>
    </div>

    <div class="mt-5 text-center" style="font-size: x-small;">
      PhilHealth's Copy
    </div>
    <div class="ml-5">
      <div class="mr-3 d-inline-block">
        <v-icon class="mb-5">mdi-content-cut</v-icon>
      </div>

      <div class="d-inline-block" style="vertical-align: top;">
        {{ "-".repeat(140) }}
      </div>
    </div>

    <div class="mt-8 text-center" width="600">
      <div class="mr-3 d-inline-block">
        <img src="@/assets/philhealth.png" alt="PhilHealth" height="30" />
      </div>

      <div class="mt-2 align-top d-inline-block">
        <strong>PhilHealth Konsulta Registration Confirmation Slip</strong>
      </div>
    </div>

    <div class="ml-12 mt-10">
      <table class="text-left">
        <tr>
          <td>
            <strong>Registration No:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 473px;">
            {{ registrationNumber }}
          </td>
        </tr>

        <tr>
          <td>
            <strong>Date registered:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 473px;">
            {{ dateRegistered }}
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-12">
      <table class="text-left">
        <tr>
          <td>
            <strong>Name:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 535px;">
            {{ fullName }}
          </td>
        </tr>

        <tr>
          <td>
            <strong>PIN:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 535px;">{{ pin }}</td>
        </tr>
      </table>
    </div>

    <div class="ml-12">
      <table class="text-left">
        <tr>
          <td>
            <strong>PhilHealth Konsulta Facility:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 400px;">
            MEDISURE ONLINE, INC.
          </td>
        </tr>
      </table>
    </div>

    <div class="mb-10 ml-12">
      <table class="text-left">
        <tr>
          <td style="font-size: smaller;">
            <strong>PhilHealth Konsulta Facility Address:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 385px;">
            16B WILLIAMS ST. HIGHWAY HILLS, MADALUYONG CITY
          </td>
        </tr>
      </table>
    </div>

    <div class="ml-12 mt-15">
      <table class="text-center">
        <tr>
          <td style="width: 270px;" />
          <td style="border-bottom: 1px solid; width: 300px;">{{ name }}</td>
        </tr>

        <tr>
          <td />
          <td style="font-size: x-small;">
            Signature over printed name of Authorized Personnel
          </td>
        </tr>
      </table>
    </div>

    <div class="mt-10 text-center" style="font-size: x-small;">
      Beneficiary's Copy
    </div>
  </div>
</template>

<script>
import { DB } from "@/common/store";
import { IS_STAGING } from "@/common/main";

import { db } from "@/main";
import { getCurrentUser } from "@/common/store";
import { getUserDetails } from "../../utilities/user";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dateRegistered: "",
    emailAddress: "",
    fullName: "",
    isMember: true,
    pin: "",
    registrationNumber: ""
  }),
  

  computed: {
    ...mapGetters({
      userCode: "getActiveUserCode",
      userInfo: "getActiveUserInfo"
    }),

    name() {
      return getCurrentUser().toUpperCase();
    }
  },

  mounted() {
    this.fullName = [
      this.userInfo.firstName || "",
      this.userInfo.middleName || "",
      this.userInfo.lastName || ""
    ].join(" ").toUpperCase();

    getUserDetails(this.userCode).then(info => {
      console.log(info);
      this.emailAddress = info.email.toUpperCase();
    });

    this.getPIN();
  },

  methods: {
    getPIN() {
      db.collection(DB.PROFILES)
        .where("userCode", "==", this.userCode)
        .limit(1)
        .get()
        .then(snapshot => {
          let uid = "";

          snapshot.forEach(doc => {
            uid = doc.id;
          });

          if (uid) {
            const coll = (IS_STAGING ? "test_" : "") + `user_${uid}`;
            db.collection(coll)
              .doc("philhealthInfo")
              .get()
              .then(doc => {
                const details = doc.data();

                if(details){
                  this.pin = details.philHealthId;
                  this.dateRegistered = details.submissionDate;
                }
                
                this.dateRegistered = new Date().toISOString().substr(0, 10);

              });
          }
        });
    }
  }
};
</script>

<style>
.center-div {
  margin: auto;
}

.align-top {
  vertical-align: top;
}

@media print {
  .section-to-print,
  .section-to-print * {
    visibility: visible;
  }
}
</style>
