<template>
  <div>
    <v-select
      :items="times"
      :label="label"
      :disabled="isDisabled"
      persistent-hint
      :hint="hint"
      v-model="selectedTime"
      @change="timeChanged"
    />
  </div>
</template>

<script>
import { GetTime, GetTimeFromValue } from "@/common/utilities/time";

export default {
  name: "TimePicker",

  props: {
    hint: String,
    isDisabled: Boolean,
    label: String,
    selectedTimeEntry: String,
    startTime: { type: Number, default: -1 }
  },

  data() {
    return {
      selectedTime: null,
      times: []
    };
  },

  mounted() {
    this.updateTimes();
  },

  methods: {
    timeChanged() {
      this.$emit("onChange", GetTimeFromValue(this.selectedTime));
    },

    updateSelectedTime() {
      this.selectedTime = this.times.find(
        x => x.text == this.selectedTimeEntry
      );
    },

    updateTimes() {
      ["AM", "PM"].forEach(meridien => {
        for (let h = 0; h < 12; h++) {
          for (let m = 0; m < 60; m += 30) {
            let time = GetTime(0 === h ? 12 : h, m, meridien);
            if (this.startTime < time.value) {
              this.times.push({ ...time });
            }
          }
        }
      });

      if (this.startTime > -1) {
        this.selectedTime = this.times[0];
      } else {
        this.updateSelectedTime();
      }
    }
  },

  watch: {
    selectedTimeEntry() {
      this.updateSelectedTime();
    },

    startTime() {
      this.updateTimes();
    }
  }
};
</script>
