<template>
  <div>
    <Header header="PHYSICAL EXAMINATION" class="mb-5" />

    <table class="equal-columns show-borders">
      <caption class="mb-2">
        Vital Signs
      </caption>

      <tr>
        <th class="header-entry" scope="col">Height</th>
        <th class="header-entry" scope="col">Weight</th>
        <th class="header-entry" scope="col">Blood Pressure</th>
        <th class="header-entry" scope="col">Pulse</th>
        <th class="header-entry" scope="col">Respiration</th>
      </tr>
      <tr>
        <td class="height-30 show-borders text-center">{{ height }} cm</td>
        <td class="height-30 show-borders text-center">{{ weight }} kg</td>
        <td class="height-30 show-borders text-center">{{ bp }}</td>
        <td class="height-30 show-borders text-center">{{ pulse }}</td>
        <td class="height-30 show-borders text-center">{{ rr }}</td>
      </tr>
    </table>

    <div class="mt-5 show-borders" style="height: 160px;">
      <div class="ma-3 smaller-bold">SUMMARY OF FINDINGS</div>

      <div class="ma-3 text-left">{{ doctor }}</div>
    </div>
    <div class="d-flex mt-5">
      <TextField
        label="EXAMINING PHYSICIAN:"
        :value="doctorName"
        class="width-left"
      />
      <TextField
        label="PRC License #:"
        :value="doctorNumber"
        class="width-right"
      />
    </div>
  </div>
</template>

<script>
import { getActiveOffsiteService } from "@/store/offsiteService";
import { getDoctorAssessment, getTriage } from "@/store/submissions";

import Header from "./Header.vue";
import TextField from "../../components/TextField.vue";

export default {
  components: {
    Header,
    TextField
  },

  data: () => ({
    serviceId: "",
    userCode: "",

    bp: "",
    pulse: 0,
    rr: 0,
    height: 0,
    weight: 0,

    doctor: "",
    doctorName: "",
    doctorNumber: ""
  }),

  mounted() {
    this.loadData();
  },

  watch: {
    serviceId() {
      this.loadData();
    },

    userCode() {
      this.loadData();
    }
  },

  methods: {
    getTriage() {
      return new Promise((resolve, reject) => {
        getTriage(this.serviceId, this.userCode)
          .then(triage => {
            this.bp = triage.bp || "";
            this.pulse = triage.pulse || 0;
            this.rr = triage.rr || 0;
            this.height = triage.height || 0;
            this.weight = triage.weight || 0;

            resolve();
          })
          .catch(err => reject(err));
      });
    },

    loadData() {
      const activeService = getActiveOffsiteService();
      this.serviceId = activeService.id;

      this.userCode = this.$store.getters["getActiveUserCode"];

      this.getTriage()
        .then(() => {
          return getDoctorAssessment(this.serviceId, this.userCode);
        })
        .then(details => {
          this.doctor = details.assessment || "";
          this.doctorName = details.doctor ? details.doctor.name || "" : "";
          this.doctorNumber = details.doctor ? details.doctor.number || "" : "";
        });
    }
  }
};
</script>

<style lang="scss" scoped>
.date {
  margin-left: 500px;
  width: 220px;
}

.equal-columns {
  table-layout: fixed;
  width: 100%;
}

.show-borders {
  border: 1px solid black;
  border-collapse: collapse;
}

.smaller-bold {
  font-size: small;
  font-weight: bold;
}

.header-entry {
  font-weight: bold;
  font-size: smaller;

  @extend .show-borders;
}

.height-30 {
  height: 30px;
}

.width-left {
  width: 450px;
}

.width-right {
  margin-left: 25px;
  width: 240px;
}
</style>
