<template>
  <div>
    <PageHeader
      header="Unassigned Clients"
      :subheader="activeService.name || 'No service selected'"
      class="mb-5"
    />

    <p v-show="reason" class="mb-10 red--text text-center">
      Unable to get data: {{ reason }}
    </p>

    <p v-show="wait" class="mb-10 green--text text-center">
      Processing Request... Please wait...
    </p>

    <v-data-table :items-per-page="15" dense v-show="!wait" :headers="headers" :items="items">
      <template v-slot:item="row">
        <tr>
          <td class="text-center">{{ row.item.userCode }}</td>
          <td>{{ row.item.lastName }}</td>
          <td>{{ row.item.firstName }}</td>
          <td>{{ row.item.middleName }}</td>
          <td class="text-center">
            <v-btn
              v-if="activeService.id"
              @click="register(row.item.id)"
              class="white--text"
              color="green"
              x-small
            >
              Register
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { DB } from "@/common/store";

import { db } from "@/main";
import { getActiveOffsiteService } from "@/store/offsiteService";

import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "Unassigned",

  components: {
    PageHeader
  },

  data: () => ({
    activeService: { id: null, name: null },
    headers: [
      { text: "User Code", value: "userCode", align: "center" },
      { text: "Last Name", value: "lastName", align: "center" },
      { text: "First Name", value: "firstName", align: "center" },
      { text: "Middle Name", value: "middleName", align: "center" },
      { text: "Action", value: "action", align: "center" }
    ],
    items: [],
    reason: "",
    wait: false
  }),

  mounted() {
    this.activeService = getActiveOffsiteService();
    this.getUnassigned();
  },

  methods: {
    getUnassigned() {
      this.items = [];

      db.collection(DB.OFFSITE_ATTENDEE)
        .where("service", "==", null)
        .get()
        .then(snapshot => {
          snapshot.forEach(doc => {
            const user = doc.data().user;
            if (user) {
              this.items.push({
                id: doc.id,
                firstName: user.firstName || "",
                lastName: user.lastName || "",
                middleName: user.middleName || "",
                userCode: user.code || ""
              });
            }
          });
        })
        .catch(err => {
          this.showError(err);
        })
        .finally(() => {
          this.wait = false;
        });
    },

    register(id) {
      this.wait = true;

      db.collection(DB.OFFSITE_ATTENDEE)
        .doc(id)
        .set(
          {
            service: {
              id: this.activeService.id,
              name: this.activeService.name
            }
          },
          { merge: true }
        )
        .then(() => {
          this.getUnassigned();
        })
        .catch(err => {
          this.showError(err);
        });
    },

    showError(err) {
      this.reason = err;
      this.wait = false;
    }
  }
};
</script>
