import { DB } from "@/common/store";
import { IS_STAGING } from "@/common/main";

import { db } from "@/main";

function getUserId(userCode) {
  return new Promise((resolve, reject) => {
    db.collection(DB.PROFILES)
      .where("userCode", "==", userCode)
      .limit(1)
      .get()
      .then(snapshot => {
        let userId = "";

        snapshot.forEach(doc => {
          userId = doc.id;
        });

        if (!userId) {
          reject("No data is available for user " + userCode);
        } else {
          resolve(userId);
        }
      })
      .catch(err => {
        reject(err);
      });
  });
}

function getUserCollection(userId) {
  return (IS_STAGING ? "test_" : "") + `user_${userId}`;
}

function getAddress(address) {
  return {
    addressLine1: address ? address.addressLine1 || "" : "",
    barangay: address ? address.barangay || "" : "",
    municipality: address ? address.municipality || "" : "",
    province: address ? address.province || "" : "",
    region: address ? address.region || "" : ""
  };
}

function getContactInfo(userId) {
  return new Promise((resolve, reject) => {
    const collection = getUserCollection(userId);
    db.collection(collection)
      .doc("contactInfo")
      .get()
      .then(doc => {
        const data = doc.data();
        resolve({
          address: getAddress(data.address),
          contactNumber: data.contactNumber || "",
          email: data.email || ""
        });
      })
      .catch(err => reject(err));
  });
}

function getMemberInfo(userId) {
  return new Promise((resolve, reject) => {
    const collection = getUserCollection(userId);
    db.collection(collection)
      .doc("memberInfo")
      .get()
      .then(doc => {
        const data = doc.data();
        resolve({
          birthDate: data.birthday || "",
          firstName: data.name ? data.name.first || "" : "",
          gender: data.sex || "",
          lastName: data.name ? data.name.last || "" : "",
          middleName: data.name ? data.name.middle || "" : ""
        });
      })
      .catch(err => reject(err));
  });
}

export function getUserDetails(userCode) {
  return new Promise((resolve, reject) => {
    let details = {};
    let userId = "";

    getUserId(userCode)
      .then(thisUserId => {
        userId = thisUserId;
        return getContactInfo(userId);
      })
      .then(data => {
        details = { ...details, ...data };
        return getMemberInfo(userId);
      })
      .then(data => {
        resolve({ ...details, ...data });
      })
      .catch(err => reject(err));
  });
}
