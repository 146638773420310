<template>
  <v-row>
    <v-col cols="12">
      <v-card min-width="320" :to="ROUTE1.ADD_OFFSITE_SERVICE">
        <v-card-title>Add Walk-in schedule</v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card min-width="320" :to="ROUTE1.UNASSIGNED">
        <v-card-title>View Unassigned Clients</v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-card min-width="320" :to="ROUTE.SET_ACTIVE_SERVICE">
        <v-card-title>
          {{ activeService.id ? "Change" : "Set" }} Active Service
        </v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12">
      <v-divider class="my-10" />
    </v-col>

    <v-col cols="12" v-if="activeService.id">
      <v-card min-width="320" :to="ROUTE1.SUBMISSIONS">
        <v-card-title>Add/Update Submissions</v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="false">
      <v-card min-width="320" to="/selectCamera">
        <v-card-title>Select Camera</v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="activeService.id">
      <v-card min-width="320" :to="ROUTE1.VIEW_REGISTERED">
        <v-card-title>View Registered Clients</v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="activeService.id">
      <v-card min-width="320" :to="ROUTE1.VIEW_SUMMARY">
        <v-card-title>View Summary</v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="false">
      <v-card min-width="320" to="/uploadPhoto" v-if="false">
        <v-card-title>Upload Photo</v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="false">
      <v-card min-width="320" to="/uploadTemperature" v-if="false">
        <v-card-title>Upload Temperature</v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="activeService.id && false">
      <v-card min-width="320" to="/viewSubmissions">
        <v-card-title>View Submissions</v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="activeService.id">
      <v-card min-width="320" :to="ROUTE1.PHILHEALTH">
        <v-card-title>Print PhilHealth Forms</v-card-title>
      </v-card>
    </v-col>

    <v-col cols="12" v-if="activeService.id">
      <v-card min-width="320" :to="ROUTE1.PRINT_RESULTS">
        <v-card-title>Print Results</v-card-title>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { ROUTE } from "@/router";
import { ROUTE1 } from "../routes";

import { getActiveOffsiteService } from "@/store/offsiteService";

export default {
  name: "MenuItems",

  data: () => ({
    activeService: { id: null, name: null, schedule: null },

    ROUTE: ROUTE,
    ROUTE1: ROUTE1
  }),

  mounted() {
    this.activeService = getActiveOffsiteService();
  }
};
</script>
