<template>
  <v-container fluid>
    <Header1
      description="ROUTING FORM"
      :apeDate="apeDate"
      :userCode="userCode"
    />

    <v-row class="mt-2">
      <v-col cols="1" />
      <v-col cols="3">
        <v-checkbox label="1. Checkin" />
      </v-col>
      <v-col cols="3">
        <v-checkbox label="5. Fecalysis" />
      </v-col>
      <v-col cols="4">
        <v-checkbox label="8. Pap Smear (optional)" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1" />
      <v-col cols="3">
        <v-checkbox label="2. Triage" class="mt-0" />
      </v-col>
      <v-col cols="3">
        <v-checkbox label="6. Xray" class="mt-0" />
      </v-col>
      <v-col cols="4">
        <v-checkbox label="9. Doctor's Assessment" class="mt-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1" />
      <v-col cols="3">
        <v-checkbox label="3. Blood Extraction" class="mt-0" />
      </v-col>
      <v-col cols="3">
        <v-checkbox label="7. ECG (optional)" class="mt-0" />
      </v-col>
      <v-col cols="3">
        <v-checkbox label="10. Checkout" class="mt-0" />
      </v-col>
    </v-row>

    <v-row>
      <v-col cols="1" />
      <v-col cols="3">
        <v-checkbox label="4. Urinalysis" class="mt-0" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

import Header1 from "../components/Header1.vue";

export default {
  name: "Tracking",

  components: {
    Header1
  },

  computed: {
    ...mapGetters({
      apeDate: "getActiveApeDate",
      userCode: "getActiveUserCode"
    })
  }
};
</script>

<style scoped>
.test-checkbox {
  margin-left: 150px;
}

.test-checkbox >>> .v-messages {
  display: none !important;
}
</style>
