<template>
  <div class="text-center" v-show="submissionUrl">
    <embed
      :src="
        submissionUrl +
          '#navpanes=0&scrollbar=0&statusbar=0&view=fitH&zoom=scale'
      "
      width="800"
      height="800"
    />
  </div>
</template>

<script>
import { getUploadData } from "@/store/offsiteService";

export default {
  props: {
    resultCode: String,
    serviceId: String,
    userCode: String
  },

  data: () => ({
    lastSubmissionDate: null,
    submissionUrl: "",
    targetFile: ""
  }),

  mounted() {
    this.loadData();
  },

  computed: {
    isButtonDisabled() {
      return this.targetFile ? false : true;
    }
  },

  watch: {
    resultCode() {
      this.loadData();
    },

    serviceId() {
      this.loadData();
    },

    userCode() {
      this.loadData();
    }
  },

  methods: {
    loadData() {
      return new Promise(resolve => {
        this.lastSubmissionDate = "";
        this.submissionUrl = "";

        getUploadData(this.serviceId, this.userCode, this.resultCode)
          .then(data => {
            this.lastSubmissionDate = data.timestamp || "";
            this.submissionUrl = data.url || "";
            this.$emit("onData", this.submissionUrl.length > 0);
          })
          .finally(() => resolve());
      });
    }
  }
};
</script>
