<template>
  <div>
    <PageHeader
      header="Print Results"
      :subheader="activeService.name || 'No service selected'"
      class="mb-5"
    />

    <p v-show="reason" class="mb-10 red--text text-center">
      Unable to get data: {{ reason }}
    </p>

    <p v-show="wait" class="mb-10 green--text text-center">
      Processing Request... Please wait...
    </p>

    <v-data-table :items-per-page="15" dense v-show="!wait" :headers="headers" :items="items">
      <template v-slot:item="row">
        <tr>
          <td class="text-center">{{ row.item.userCode }}</td>
          <td>{{ row.item.lastName }}</td>
          <td>{{ row.item.firstName }}</td>
          <td>{{ row.item.middleName }}</td>
          <td class="text-center">
            <v-btn
              @click="printResults(row.item)"
              class="white--text"
              color="green"
              x-small
            >
              Print Results
            </v-btn>
          </td>
        </tr>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ROUTE1 } from "../routes";

import {
  getActiveOffsiteService,
  getSubmissions
} from "@/store/offsiteService";

import PageHeader from "@/components/PageHeader.vue";

export default {
  name: "Unassigned",

  components: {
    PageHeader
  },

  data: () => ({
    activeService: { id: null, name: null },
    headers: [
      { text: "User Code", value: "userCode", align: "center" },
      { text: "Last Name", value: "lastName", align: "center" },
      { text: "First Name", value: "firstName", align: "center" },
      { text: "Middle Name", value: "middleName", align: "center" },
      { text: "Action", value: "action", align: "center" }
    ],
    items: [],
    reason: "",
    wait: false
  }),

  mounted() {
    this.wait = true;
    this.activeService = getActiveOffsiteService();

    this.items = [];
    getSubmissions(this.activeService.id)
      .then(users => {
        Object.keys(users).forEach(key => {
          this.items.push({
            userCode: users[key].userCode || "",
            lastName: users[key].lastName || "",
            firstName: users[key].firstName || "",
            middleName: users[key].middleName || "",
            birthday: users[key].birthday || "",
            gender: users[key].gender || ""
          });
        });

        this.wait = false;
      })
      .catch(err => (this.reason = err));
  },

  methods: {
    printResults(item) {
      this.$store
        .dispatch("setActiveApeDate", new Date(this.activeService.schedule))
        .then(() => {
          this.$store.dispatch("setActiveUserCode", item.userCode);
        })
        .then(() => {
          this.$store.dispatch("setActiveUserInfo", item);
        })
        .then(() => {
          this.$router.push({ path: ROUTE1.RESULTS });
        });
    },

    showError(err) {
      this.reason = err;
      this.wait = false;
    }
  }
};
</script>
