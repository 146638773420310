<template>
  <div style="font-size: small;">
    <div class="text-center" width="600">
      <div class="mr-3 d-inline-block">
        <img src="@/assets/philhealth.png" alt="PhilHealth" height="30" />
      </div>

      <div class="mt-2 align-top d-inline-block">
        <strong>Electronic Konsulta Availment Slip (eKAS)</strong>
      </div>
    </div>

    <div class="ml-12 mt-5">
      <table class="text-left" style="font-size: smaller;">
        <tr>
          <td>
            <strong>HCI Name:</strong>
          </td>
          <td style="border-bottom: 1px solid;">
            MEDISURE ONLINE, INC.
          </td>

          <td>
            <strong>Case No.:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 50px;" />

          <td>
            <strong>HCI Accreditation No.:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 50px;" >
          P91033332</td>
              
          <td>
            <strong>Transaction No.:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 50px;" />
        </tr>
      </table>

      <table class="mt-5 text-left" style="font-size: smaller;">
        <tr>
          <td>
            <strong>Patient Name</strong>
            (Pangalan ng pasyente)
            <strong>:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 170px;">
            {{ fullName }}
          </td>

          <td>
            <strong>Age</strong>
            <strong>:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 25px;" >
        {{age}}
          </td>
          <td>
            <strong>Contact No.:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 70px;" >
          {{contact}}
          </td>
        
        </tr>
      </table>

      <table class="text-left" style="font-size: smaller;">
        <tr>
          <td>
            <strong>PIN</strong>
            (PhilHealth Identification Number)
            <strong>:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 183px;">
            {{ pin }}
          </td>

          <td>
            <strong>Membership Category:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 100px;" />
        </tr>
      </table>

      <table class="text-left" style="font-size: smaller;">
        <tr>
          <td>
            <strong>Membership type:</strong>
          </td>
          <td
            class="text-center"
            style="border-bottom: 1px solid; width: 25px;"
          >
            <span v-show="isMember">&#x2713;</span>
          </td>
          <td>Member</td>
          <td
            class="text-center"
            style="border-bottom: 1px solid; width: 25px;"
          >
            <span v-show="!isMember">&#x2713;</span>
          </td>
          <td>Dependent</td>

          <td style="width: 70px;" />

          <td>
            <strong>Authorization Transaction Code</strong>
            (ATC)
            <strong>:</strong>
          </td>
          <td
            class="text-center"
            style="border-bottom: 1px solid; width: 75px;"
          />
        </tr>
      </table>

      <table
        class="mt-5 text-left"
        style="background-color: #d4d0ce; border: 1px solid; font-size: smaller; color-adjust: exact;  -webkit-print-color-adjust: exact; print-color-adjust: exact;"
      >
        <tr>
          <td style="width: 700px;">
            To be filled out by the facility (pupunuan ng pasilidad)
          </td>
        </tr>
      </table>

      <table class="mt-5 text-center" style="font-size: smaller; border: 1px solid;">
        <tr>
          <td
            style="border-bottom: 1px solid; border-right: 1px solid; width: 300px;"
          >
            Konsulta Services
          </td>
          <td style="border-bottom: 1px solid; border-right: 1px solid;">
            <u>&#x2713;</u> Performed (nagawa)<br />
            <u>X</u> Not Performed (hindi nagawa)
          </td>
          <td style="border-bottom: 1px solid; border-right: 1px solid;">
            Date performed<br />
            (Petsa kung kelan ginawa)
          </td>
          <td class="ml-0" style="border-bottom: 1px solid;">
            Performed by<br />
            (Ginawa ni)<br />
            (Initial/Signature of Health care Provider/technician)<br />
            (Initial o Lagda ng Health care Provider/technician)
          </td>
        </tr>

        <tr>
          <td
            class="text-left"
            style="border-bottom: 1px solid; border-right: 1px solid;"
          >
            History and physical examination (vitals, anthropometrics)
          </td>

          <td style="border-bottom: 1px solid; border-right: 1px solid;" />
          <td style="border-bottom: 1px solid; border-right: 1px solid;" />
          <td style="border-bottom: 1px solid;" />
        </tr>

        <tr>
          <td class="text-left" style="border-right: 1px solid;">
            CBC
          </td>

          <td style="border-right: 1px solid;" />
          <td style="border-right: 1px solid;" />
        </tr>
      </table>

      <table
        class="mt-5 text-left"
        style="background-color: #d4d0ce; border: 1px solid; font-size: smaller; color-adjust: exact;  -webkit-print-color-adjust: exact; print-color-adjust: exact;"
      >
        <tr>
          <td style="width: 700px;">
            To be filled out by the patient (pupunuan ng pasyente)
          </td>
        </tr>
      </table>

      <table class="mt-5 text-left" style="font-size: smaller;">
        <tr>
          <td style="width: 375px;">
            Have you received the above-mentioned essential services?<br />
            <strong>
              (Natanggap mo ba ang mga essential services na nabanggit?)
            </strong>
          </td>
          <td>
            _____ Yes &nbsp;&nbsp;&nbsp;&nbsp; _____ No
          </td>
        </tr>

        <tr>
          <td>
            <div class="mt-3">
              How satisfied are you with the services provided?<br />
              <strong>
                (Gaano ka nasiyahan sa natanggap mong serbisyo?)
              </strong>
            </div>
          </td>
          <td>
            <div class="mt-3">
              <img src="@/assets/evaluation.png" alt="Evaluation" height="30" />
            </div>
          </td>
        </tr>
      </table>

      <table class="mt-3 text-left" style="font-size: smaller;">
        <tr>
          <td style="width: 700px;">
            For your comment, suggestion or complaint:<br />
            (Para sa iyong komento, mungkahi o reklamo)
          </td>
        </tr>

        <tr>
          <td style="border-bottom: 1px solid;">
            <div class="mt-1">&nbsp;</div>
          </td>
        </tr>

        <tr>
          <td style="border-bottom: 1px solid;">
            <div class="mt-1">&nbsp;</div>
          </td>
        </tr>
      </table>

      <table class="mt-5 text-left" style="font-size: smaller;">
        <tr>
          <td style="width: 700px;">
            Under the penalty of law, I attest that the information I provided
            in this slip are true and accurate.<br />
            <strong>
              (Sa ilalim ng batas, pinatutunayan ko na ang impormasyong ibinigay
              ko ay totoo at tama)
            </strong>
          </td>
        </tr>
      </table>

      <table class="mt-5 text-left" style="font-size: smaller;">
        <tr>
          <td style="width: 350px;"/>
          <td style="width: 150px;">
            <strong>Next Consultation Date:</strong>
          </td>
          <td style="border-bottom: 1px solid; width: 200px;"/>
        </tr>
      </table>

      <table class="text-left" style="font-size: smaller;">
        <tr>
          <td style="width: 350px;" />
          <td style="width: 350px;">(Petsa ng susunod na konsultasyon)</td>
        </tr>
      </table>

      <table class="mt-5 text-center" style="font-size: smaller;">
        <tr>
          <td style="border-bottom: 1px solid; width: 300px;">
            {{ fullName }}
          </td>
        </tr>

        <tr>
          <td>
            <strong>Signature over printed name of patient</strong>
          </td>
        </tr>

        <tr>
          <td>(Lagda sa nakalimbag na pangalan ng pasyente)</td>
        </tr>
      </table>

      <table class="mt-5 text-left" style="font-size: smaller;">
        <tr>
          <td style="width: 300px;">
            Note:<br />
            Accomplished form shall be submitted to PhilHealth.<br />
            <strong>
              (Ang kumpletong form ay dapat isumite sa PhilHealth)
            </strong>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<script>
import { DB, getCurrentUser } from "@/common/store";
import { IS_STAGING } from "@/common/main";

import { db } from "@/main";
import { computeAge } from "@/common/utilities/date";
import { getUserDetails } from "../../utilities/user";
import { mapGetters } from "vuex";

export default {
  data: () => ({
    dateRegistered: "",
    emailAddress: "",
    fullName: "",
    isMember: true,
    pin: "",
    registrationNumber: "",
    contact: ""
  }),

  computed: {
    ...mapGetters({
      userCode: "getActiveUserCode",
      userInfo: "getActiveUserInfo"
    }),

    name() {
      return getCurrentUser();
    },

    age(){ 
    return computeAge(
      new Date(),
      new Date(this.userInfo.birthday)
    )
    }
  },

  mounted() {
    this.fullName = [
      this.userInfo.firstName || "",
      this.userInfo.middleName || "",
      this.userInfo.lastName || ""
    ]
      .join(" ")
      .toUpperCase();

    getUserDetails(this.userCode).then(info => {
      this.contact = info.contactNumber
      this.emailAddress = info.email.toUpperCase();
    });

    this.getPIN();
  },

  methods: {
    getPIN() {
      db.collection(DB.PROFILES)
        .where("userCode", "==", this.userCode)
        .limit(1)
        .get()
        .then(snapshot => {
          let uid = "";

          snapshot.forEach(doc => {
            uid = doc.id;
          });

          if (uid) {
            const coll = (IS_STAGING ? "test_" : "") + `user_${uid}`;
            db.collection(coll)
              .doc("philhealthInfo")
              .get()
              .then(doc => {
                const details = doc.data();
                this.pin = details.philHealthId;
                this.dateRegistered = details.submissionDate;
              });
          }
        });
    }
  }
};
</script>

<style>
.center-div {
  margin: auto;
}

.align-top {
  vertical-align: top;
}

@media print {
  .section-to-print,
  .section-to-print * {
    visibility: visible;
  }
}
</style>
