<template>
  <v-container fluid class="main-container text-center">
    <PageHeader
      header="View Summary"
      :subheader="activeService.name || 'No service selected'"
    />
    <Attendees />

    <v-select
      :items="itemFilters"
      v-model="submissionFilter"
      @change="filterChanged"
      class="mt-10"
      label="Filter submissions"
      style="margin: auto; max-width: 320px;"
    />

    <v-data-table :items-per-page="15" dense :headers="headers" :items="filteredItems" />
  </v-container>
</template>

<script>
import {
  FILTER_ALL,
  FILTER_COMPLETE,
  FILTER_INCOMPLETE,
  HEADER,
  STAGE,
  getActiveOffsiteService,
  getSubmissions
} from "@/store/offsiteService";

import Attendees from "../components/Attendees.vue";
import PageHeader from "@/components/PageHeader.vue";

const INDEX = {
  [HEADER.CHECKIN]: 2,
  [HEADER.TRIAGE]: 3,
  [HEADER.BLOOD_EXTRACTION]: 4,
  [HEADER.URINALYSIS]: 5,
  [HEADER.FECALYSIS]: 6,
  [HEADER.XRAY]: 7,
  [HEADER.ECG]: 8,
  [HEADER.PAP_SMEAR]: 9,
  [HEADER.DOCTOR]: 10,
  [HEADER.CHECKOUT]: 11
};

export default {
  name: "ViewSubmissions",

  components: {
    Attendees,
    PageHeader
  },

  data: () => ({
    activeService: { id: null, name: null },
    headers: [
      { text: "Name", value: HEADER.NAME },
      { text: "User Code", value: HEADER.USER_CODE, align: "center" },
      { text: "Checkin", value: HEADER.CHECKIN, align: "center" },
      { text: "Triage", value: HEADER.TRIAGE, align: "center" },
      {
        text: "Blood Extraction",
        value: HEADER.BLOOD_EXTRACTION,
        align: "center"
      },
      { text: "Urine Sample", value: HEADER.URINALYSIS, align: "center" },
      { text: "Fecal Sample", value: HEADER.FECALYSIS, align: "center" },
      { text: "X-ray", value: HEADER.XRAY, align: "center" },
      { text: "ECG (optional)", value: HEADER.ECG, align: "center" },
      {
        text: "Pap Smear (optional)",
        value: HEADER.PAP_SMEAR,
        align: "center"
      },
      { text: "Doctor's Assessment", value: HEADER.DOCTOR, align: "center" },
      { text: "Checkout", value: HEADER.CHECKOUT, align: "center" }
    ],
    itemFilters: [FILTER_ALL, FILTER_COMPLETE, FILTER_INCOMPLETE],
    items: [],
    filteredItems: [],
    sampleCode: "",
    submissionFilter: "",
    submissions: {
      [HEADER.CHECKIN]: 0,
      [HEADER.TRIAGE]: 0,
      [HEADER.BLOOD_EXTRACTION]: 0,
      [HEADER.URINALYSIS]: 0,
      [HEADER.FECALYSIS]: 0,
      [HEADER.XRAY]: 0,
      [HEADER.ECG]: 0,
      [HEADER.PAP_SMEAR]: 0,
      [HEADER.DOCTOR]: 0,
      [HEADER.CHECKOUT]: 0
    },
    totalSubmissions: 0
  }),

  mounted() {
    this.activeService = getActiveOffsiteService();
    this.submissionFilter = FILTER_ALL;

    getSubmissions(this.activeService.id).then(data => {
      for (const key in data) {
        this.items.push(this.getData(data[key], key));
        this.incrementSubmissions(data[key]);
      }

      this.filteredItems = [...this.items];
      this.updateHeaders();
    });
  },

  methods: {
    filterChanged() {
      switch (this.submissionFilter) {
        case FILTER_ALL:
          this.filteredItems = [...this.items];
          break;

        case FILTER_COMPLETE:
          this.filteredItems = this.items.filter(doc => this.isComplete(doc));
          break;

        case FILTER_INCOMPLETE:
          this.filteredItems = this.items.filter(doc => !this.isComplete(doc));
          break;
      }
    },

    incrementSubmissions(data) {
      this.submissions[HEADER.CHECKIN] += data[STAGE.CHECKIN] ? 1 : 0;
      this.submissions[HEADER.TRIAGE] += data[STAGE.TRIAGE] ? 1 : 0;
      this.submissions[HEADER.BLOOD_EXTRACTION] += data[STAGE.BLOOD_EXTRACTION]
        ? 1
        : 0;
      this.submissions[HEADER.URINALYSIS] += data[STAGE.URINALYSIS] ? 1 : 0;
      this.submissions[HEADER.FECALYSIS] += data[STAGE.FECALYSIS] ? 1 : 0;
      this.submissions[HEADER.XRAY] += data[STAGE.XRAY] ? 1 : 0;
      this.submissions[HEADER.ECG] += data[STAGE.ECG] ? 1 : 0;
      this.submissions[HEADER.PAP_SMEAR] += data[STAGE.PAP_SMEAR] ? 1 : 0;
      this.submissions[HEADER.DOCTOR] += data[STAGE.DOCTOR] ? 1 : 0;
      this.submissions[HEADER.CHECKOUT] += data[STAGE.CHECKOUT] ? 1 : 0;

      this.totalSubmissions += 1;
    },

    getData(data, userCode) {
      return {
        ...data,
        [HEADER.USER_CODE]: userCode,
        [HEADER.CHECKIN]: data[STAGE.CHECKIN] ? "DONE" : "",
        [HEADER.TRIAGE]: data[STAGE.TRIAGE] ? "DONE" : "",
        [HEADER.BLOOD_EXTRACTION]: data[STAGE.BLOOD_EXTRACTION] ? "DONE" : "",
        [HEADER.URINALYSIS]: data[STAGE.URINALYSIS] ? "DONE" : "",
        [HEADER.FECALYSIS]: data[STAGE.FECALYSIS] ? "DONE" : "",
        [HEADER.XRAY]: data[STAGE.XRAY] ? "DONE" : "",
        [HEADER.ECG]: data[STAGE.ECG] ? "DONE" : "",
        [HEADER.PAP_SMEAR]: data[STAGE.PAP_SMEAR] ? "DONE" : "",
        [HEADER.DOCTOR]: data[STAGE.DOCTOR] ? "DONE" : "",
        [HEADER.CHECKOUT]: data[STAGE.CHECKOUT] ? "DONE" : ""
      };
    },

    isComplete(doc) {
      return (
        doc[HEADER.USER_CODE] &&
        doc[HEADER.CHECKIN] &&
        doc[HEADER.TRIAGE] &&
        doc[HEADER.BLOOD_EXTRACTION] &&
        doc[HEADER.URINALYSIS] &&
        doc[HEADER.FECALYSIS] &&
        doc[HEADER.XRAY] &&
        doc[HEADER.DOCTOR] &&
        doc[HEADER.CHECKOUT]
      );
    },

    updateHeader(index, headerBase, headerKey) {
      this.headers[index]["text"] = `${headerBase} (${String(
        this.submissions[headerKey]
      )} of ${String(this.totalSubmissions)})`;
    },

    updateHeaders() {
      this.updateHeader(INDEX[HEADER.CHECKIN], "Checkin", HEADER.CHECKIN);
      this.updateHeader(INDEX[HEADER.TRIAGE], "Triage", HEADER.TRIAGE);
      this.updateHeader(
        INDEX[HEADER.BLOOD_EXTRACTION],
        "Blood Extraction",
        HEADER.BLOOD_EXTRACTION
      );
      this.updateHeader(
        INDEX[HEADER.URINALYSIS],
        "Urine Sample",
        HEADER.URINALYSIS
      );
      this.updateHeader(
        INDEX[HEADER.FECALYSIS],
        "Fecal Sample",
        HEADER.FECALYSIS
      );
      this.updateHeader(INDEX[HEADER.XRAY], "X-ray", HEADER.XRAY);
      this.updateHeader(INDEX[HEADER.ECG], "ECG (optional)", HEADER.ECG);
      this.updateHeader(
        INDEX[HEADER.PAP_SMEAR],
        "Pap Smear (optional)",
        HEADER.PAP_SMEAR
      );
      this.updateHeader(
        INDEX[HEADER.DOCTOR],
        "Doctor's Assessment",
        HEADER.DOCTOR
      );
      this.updateHeader(INDEX[HEADER.CHECKOUT], "Checkout", HEADER.CHECKOUT);
    }
  }
};
</script>
