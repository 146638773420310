<template>
  <v-container fluid class="main-container text-center">
    <PageHeader header="Upload Temperature" class=" mb-10" />

    <UserCode
      @onInvalidUserCode="onInvalidUserCode"
      @onValidUserCode="onValidUserCode"
    />

    <UserInfoWithValidation
      :code="userCode"
      @onUserInfo="onUserInfo"
      :isUserCodeValid="isUserCodeValid"
    />
    <UserImage
      v-show="isUserCodeValid && serviceId"
      :code="userCode"
      :serviceId="serviceId"
    />

    <v-text-field
      label="Temperature (in Celsius)"
      v-model="temperature"
      v-show="isUserCodeValid && serviceId"
      class="mt-5"
      style="margin: auto; max-width: 320px;"
      type="number"
    ></v-text-field>

    <v-row class="mt-10">
      <v-btn
        :disabled="!isTemperatureChanged"
        v-show="isUserCodeValid && serviceId"
        @click="addTemperature"
        color="primary"
        style="margin: auto; max-width: 320px;"
      >
        {{ saveTemperatureText }}
      </v-btn>
    </v-row>

    <ErrorMessage :errorMessage="errorMessage" />

    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
import { addTemperature, getTemperature } from "../store";

import ConfirmDlg from "@/components/ConfirmDlg.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import PageHeader from "@/components/PageHeader.vue";
import UserCode from "@/components/UserCode.vue";
import UserImage from "@/components/UserImage.vue";
import UserInfoWithValidation from "@/components/UserInfoWithValidation.vue";

export default {
  name: "UploadTemperature",

  components: {
    ConfirmDlg,
    ErrorMessage,
    PageHeader,
    UserCode,
    UserImage,
    UserInfoWithValidation
  },

  data: () => ({
    isUserCodeValid: false,
    userCode: "",
    imageSrc: "",

    serviceId: "",

    temperature: "",
    lastTemperature: "",

    saveTemperatureText: "Add Temperature",
    errorMessage: ""
  }),

  methods: {
    addTemperature() {
      addTemperature(this.serviceId, this.userCode, this.temperature)
        .then(() => {
          this.$refs.confirm.open("Confirmation", "Temperature saved", {
            noconfirm: true
          });

          this.lastTemperature = this.temperature;
        })
        .catch(err => {
          this.temperature = "";
          this.errorMessage = "Checkin Error: " + err;
        });
    },

    onInvalidUserCode(userCode) {
      this.userCode = userCode;
      this.imageSrc = "";

      this.isUserCodeValid = false;
      this.savePhotoText = "Add Temperature";
    },

    onUserInfo(userInfo) {
      this.serviceId =
        userInfo && userInfo.service && userInfo.service.id
          ? userInfo.service.id
          : "";
    },

    onValidUserCode(userCode) {
      this.userCode = userCode;
      this.isUserCodeValid = true;

      getTemperature(this.serviceId, this.userCode)
        .then(result => {
          this.lastTemperature = result.temperature;
          this.temperature = result.temperature;
        })
        .catch(
          err => (this.errorMessage = "User data retrieval error: " + err)
        );
    }
  },

  computed: {
    isTemperatureChanged() {
      return this.temperature !== this.lastTemperature;
    }
  },

  watch: {
    isTemperatureChanged() {
      this.saveTemperatureText =
        this.lastTemperature && this.isTemperatureChanged
          ? "Update Temperature"
          : "Add Temperature";
    }
  }
};
</script>
