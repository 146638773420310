<template>
  <TimePicker
    :isDisabled="isDisabled"
    :label="label"
    @onChange="timeChanged"
    :startTime="startTime"
  />
</template>

<script>
import TimePicker from "./TimePicker.vue";

export default {
  name: "ToTimePicker",

  components: {
    TimePicker
  },

  props: {
    isDisabled: Boolean,
    label: String,
    startTime: Number
  },

  methods: {
    timeChanged(time) {
      this.$emit("onChange", time);
    }
  }
};
</script>
