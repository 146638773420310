import AddOffsiteService from "./views/AddOffsiteService.vue";
import Menu from "./views/Menu.vue";
import PhilHealth from "./views/PhilHealth.vue";
import PhilHealthEKAS from "./views/PhilHealth/eKAS.vue";
import PhilHealthRegistration from "./views/PhilHealth/Registration.vue";
import PrintResults from "./views/PrintResults.vue";
import Results from "./views/Results.vue";
import Submissions from "./views/Submissions.vue";
import Tracking from "./views/Tracking.vue";
import Unassigned from "./views/Unassigned.vue";
import UploadPhoto from "./views/UploadPhoto.vue";
import UploadTemperature from "./views/UploadTemperature.vue";
import ViewRegistered from "./views/ViewRegistered.vue";
import ViewSummary from "./views/ViewSummary.vue";

export const ROUTE1 = {
  ADD_OFFSITE_SERVICE: "/addOffsiteService",
  PHILHEALTH: "/philHealth",
  PHILHEALTH_EKAS: "/philHealth/eKAS",
  PHILHEALTH_REGISTRATION: "/philHealth/registration",
  PRINT_RESULTS: "/printResults",
  RESULTS: "/results",
  SUBMISSIONS: "/submissions",
  TRACKING: "/tracking",
  UNASSIGNED: "/unassigned",
  VIEW_REGISTERED: "/viewRegistered",
  VIEW_SUMMARY: "/viewSummary"
};

export function getRoutes(ROUTE, guard) {
  return [
    {
      path: ROUTE1.ADD_OFFSITE_SERVICE,
      name: "Add Offsite Service",
      component: AddOffsiteService,
      beforeEnter: guard
    },
    {
      path: ROUTE.MENU,
      name: "Menu",
      component: Menu,
      beforeEnter: guard
    },
    {
      path: ROUTE1.PHILHEALTH,
      name: "PhilHealth",
      component: PhilHealth,
      beforeEnter: guard
    },
    {
      path: ROUTE1.PHILHEALTH_EKAS,
      name: "PhilHealth eKAS",
      component: PhilHealthEKAS,
      beforeEnter: guard
    },
    {
      path: ROUTE1.PHILHEALTH_REGISTRATION,
      name: "PhilHealth Registration",
      component: PhilHealthRegistration,
      beforeEnter: guard
    },
    {
      path: ROUTE1.PRINT_RESULTS,
      name: "Print Results",
      component: PrintResults,
      beforeEnter: guard
    },
    {
      path: ROUTE1.RESULTS,
      name: "Results",
      component: Results,
      beforeEnter: guard
    },
    {
      path: ROUTE1.SUBMISSIONS,
      name: "Submissions",
      component: Submissions,
      beforeEnter: guard
    },
    {
      path: ROUTE1.TRACKING,
      name: "Tracking",
      component: Tracking,
      props: true,
      beforeEnter: guard
    },
    {
      path: ROUTE1.UNASSIGNED,
      name: "Unassigned",
      component: Unassigned,
      beforeEnter: guard
    },
    {
      path: "/uploadPhoto",
      name: "UploadPhoto",
      component: UploadPhoto,
      beforeEnter: guard
    },
    {
      path: "/uploadTemperature",
      name: "UploadTemperature",
      component: UploadTemperature,
      beforeEnter: guard
    },
    {
      path: ROUTE1.VIEW_REGISTERED,
      name: "View Registered",
      component: ViewRegistered,
      beforeEnter: guard
    },
    {
      path: ROUTE1.VIEW_SUMMARY,
      name: "View Summary",
      component: ViewSummary,
      beforeEnter: guard
    }
  ];
}
