<template>
  <v-container fluid class="main-container text-center">
    <PageHeader
      header="Submissions"
      :subheader="activeService.name || 'No service selected'"
    />
    <Attendees ref="attendees" class="mb-10" />

    <UserCode
      ref="userCodeComponent"
      @onInvalidUserCode="onInvalidUserCode"
      @onValidUserCode="onValidUserCode"
    />

    <UserInfo :code="userCode" @onData="onData" />
    <div
      v-show="isValidUserCode && userCode"
      class="mt-10 text-center"
      style="margin: auto; max-width: 500px;"
    >
      <v-checkbox v-model="thisCheckin" label="Check-in" />
      <v-checkbox
        v-model="thisTriage"
        disabled
        label="Triage - cannot be enabled/disabled in Registration"
        class="mt-0"
      />
      <v-checkbox
        v-model="thisBloodExtraction"
        label="Blood Extraction"
        class="mt-0"
      />
      <v-checkbox v-model="thisUrinalysis" label="Urinalysis" class="mt-0" />
      <v-checkbox v-model="thisFecalysis" label="Fecalysis" class="mt-0" />
      <v-checkbox v-model="thisXray" label="Xray" class="mt-0" />
      <v-checkbox v-model="thisECG" label="ECG (optional)" class="mt-0" />
      <v-checkbox
        v-model="thisPapSmear"
        label="Pap Smear (optional)"
        class="mt-0"
      />
      <v-checkbox
        v-model="thisDoctor"
        label="Doctor's Assessment - cannot be enabled/disabled in Registration"
        disabled
        class="mt-0"
      />
      <v-checkbox v-model="thisCheckout" label="Checkout" class="mt-0" />

      <v-btn
        @click="saveContent"
        class="mt-10"
        color="primary"
        :disabled="isSaveButtonDisabled"
        style="margin: auto; max-width: 320px;"
      >
        Save
      </v-btn>
    </div>

    <v-row>
      <v-col cols="6">
        <v-btn
          v-if="false"
          :to="'/labRequestForm?' + queryString"
          :disabled="isSaved"
          class="mt-10"
          color="primary"
          style="margin: auto; max-width: 320px;"
        >
          Print Lab Request Form
        </v-btn>
      </v-col>
    </v-row>

    <ErrorMessage :errorMessage="errorMessage" />

    <ConfirmDlg ref="confirm" />

    <SubmissionSummary
      :activeServiceId="activeService.id"
      :key="submissionKey"
    />
  </v-container>
</template>

<script>
import { HEADER, STAGE, getActiveOffsiteService } from "@/store/offsiteService";

import { addSubmission, getScheduleData } from "@/store/submissions";

import Attendees from "../components/Attendees.vue";
import ConfirmDlg from "@/components/ConfirmDlg.vue";
import ErrorMessage from "@/components/ErrorMessage.vue";
import PageHeader from "@/components/PageHeader.vue";
import SubmissionSummary from "@/components/SubmissionSummary.vue";
import UserCode from "@/components/UserCode.vue";
import UserInfo from "@/components/UserInfo.vue";

let moment = require("moment");

export default {
  name: "Checkin",

  components: {
    Attendees,
    ConfirmDlg,
    ErrorMessage,
    PageHeader,
    SubmissionSummary,
    UserCode,
    UserInfo
  },

  data: () => ({
    activeService: { id: null, name: null },

    userCode: "",
    userData: {},
    isValidUserCode: false,

    isSaved: false,
    isSaving: false,

    errorMessage: "",
    submissionKey: 0,

    lastCheckin: false,
    lastTriage: false,
    lastBloodExtraction: false,
    lastUrinalysis: false,
    lastFecalysis: false,
    lastXray: false,
    lastECG: false,
    lastPapSmear: false,
    lastDoctor: false,
    lastCheckout: false,

    thisCheckin: false,
    thisTriage: false,
    thisBloodExtraction: false,
    thisUrinalysis: false,
    thisFecalysis: false,
    thisXray: false,
    thisECG: false,
    thisPapSmear: false,
    thisDoctor: false,
    thisCheckout: false,

    HEADER: HEADER,
    STAGE: STAGE
  }),

  mounted() {
    this.activeService = getActiveOffsiteService();
    this.submissionKey++;
  },

  methods: {
    getData() {
      return new Promise((resolve, reject) => {
        getScheduleData(this.activeService.id, this.userCode)
          .then(submissions => {
            this.saveData(submissions);
            this.errorMessage = "";
            resolve();
          })
          .catch(err => {
            this.errorMessage = "getScheduleData Error: " + err;
            reject(err);
          });
      });
    },

    normalizeQueryString(candidate) {
      return (candidate || "").replace(" ", "+");
    },

    onData(data) {
      this.userData = data;
      this.errorMessage = "";

      if (this.isValidUserCode) {
        this.errorMessage = "Looking up user code. Please wait.";
        this.isSaved = false;

        if (data.name) {
          this.getData();
        } else {
          this.errorMessage = "Attendee cannot be found in the database";
        }
      }
    },

    onInvalidUserCode(userCode) {
      this.userCode = userCode;

      this.errorMessage = "";
      this.isValidUserCode = false;
    },

    onValidUserCode(userCode) {
      this.userCode = userCode;
      this.isValidUserCode = true;
    },

    saveContent() {
      this.isSaving = true;

      this.saveStages()
        .then(() => {
          if (
            this.$refs.confirm.open("Confirmation", "Save completed", {
              noconfirm: true
            })
          ) {
            this.submissionKey++;
            this.$refs.attendees.refresh();
          }
        })
        .then(() => {
          return this.getData();
        })
        .finally(() => {
          this.isSaved = true;
          this.isSaving = false;
        });
    },

    saveContentStage(stage, thisValue, lastValue) {
      return new Promise(resolve => {
        if (thisValue !== lastValue) {
          addSubmission(
            stage,
            this.activeService.id,
            this.userCode,
            thisValue
          ).finally(() => resolve());
        } else {
          resolve();
        }
      });
    },

    saveData(submissions) {
      this.thisCheckin = submissions[STAGE.CHECKIN];
      this.thisTriage = submissions[STAGE.TRIAGE];
      this.thisBloodExtraction = submissions[STAGE.BLOOD_EXTRACTION];
      this.thisUrinalysis = submissions[STAGE.URINALYSIS];
      this.thisFecalysis = submissions[STAGE.FECALYSIS];
      this.thisXray = submissions[STAGE.XRAY];
      this.thisECG = submissions[STAGE.ECG];
      this.thisPapSmear = submissions[STAGE.PAP_SMEAR];
      this.thisDoctor = submissions[STAGE.DOCTOR];
      this.thisCheckout = submissions[STAGE.CHECKOUT];

      this.lastCheckin = this.thisCheckin;
      this.lastTriage = this.thisTriage;
      this.lastBloodExtraction = this.thisBloodExtraction;
      this.lastUrinalysis = this.thisUrinalysis;
      this.lastFecalysis = this.thisFecalysis;
      this.lastXray = this.thisXray;
      this.lastECG = this.thisECG;
      this.lastPapSmear = this.thisPapSmear;
      this.lastDoctor = this.thisDoctor;
      this.lastCheckout = this.thisCheckout;
    },

    saveStages() {
      return new Promise(resolve => {
        this.saveContentStage(STAGE.CHECKIN, this.thisCheckin, this.lastCheckin)
          .then(() => {
            return this.saveContentStage(
              STAGE.TRIAGE,
              this.thisTriage,
              this.lastTriage
            );
          })
          .then(() => {
            return this.saveContentStage(
              STAGE.BLOOD_EXTRACTION,
              this.thisBloodExtraction,
              this.lastBloodExtraction
            );
          })
          .then(() => {
            return this.saveContentStage(
              STAGE.URINALYSIS,
              this.thisUrinalysis,
              this.lastUrinalysis
            );
          })
          .then(() => {
            return this.saveContentStage(
              STAGE.FECALYSIS,
              this.thisFecalysis,
              this.lastFecalysis
            );
          })
          .then(() => {
            return this.saveContentStage(
              STAGE.XRAY,
              this.thisXray,
              this.lastXray
            );
          })
          .then(() => {
            return this.saveContentStage(STAGE.ECG, this.thisECG, this.lastECG);
          })
          .then(() => {
            return this.saveContentStage(
              STAGE.PAP_SMEAR,
              this.thisPapSmear,
              this.lastPapSmear
            );
          })
          .then(() => {
            return this.saveContentStage(
              STAGE.DOCTOR,
              this.thisDoctor,
              this.lastDoctor
            );
          })
          .then(() => {
            return this.saveContentStage(
              STAGE.CHECKOUT,
              this.thisCheckout,
              this.lastCheckout
            );
          })
          .then(() => resolve());
      });
    }
  },

  computed: {
    isSaveButtonDisabled() {
      return (
        this.lastCheckin === this.thisCheckin &&
        this.lastBloodExtraction === this.thisBloodExtraction &&
        this.lastUrinalysis === this.thisUrinalysis &&
        this.lastFecalysis === this.thisFecalysis &&
        this.lastXray === this.thisXray &&
        this.lastCheckout === this.thisCheckout
      );
    },

    queryString() {
      let birthday = this.normalizeQueryString(
        this.userData && this.userData.birthday
      );
      let sex = this.normalizeQueryString(
        this.userData && this.userData.gender
      );
      let ageSex = moment().diff(moment(birthday), "years");
      ageSex = this.normalizeQueryString(ageSex + " / " + sex);

      let name = this.normalizeQueryString(this.userData && this.userData.name);
      let phone = this.normalizeQueryString(
        this.userData && this.userData.phone
      );

      return `AGE_SEX=${ageSex}&BIRTHDATE=${birthday}&NAME=${name}&PHONE=${phone}&USER_CODE=${this.userCode}`;
    }
  }
};
</script>

<style scoped>
.view-size {
  height: 330px;
  width: 330px;
}
</style>
